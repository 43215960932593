<template>
    <div class="job-page">
        <div class="row mt-5 mb-5">
            <div class="col-md-12"><h3>Jobs</h3></div>
        </div>
        <div class="mt-5 mb-5">
            <button class="btn btn-success" @click="$router.push('/enc-painel/job/new')">Cadastrar Job</button>
        </div>
        <div class="row">
            <table class="table table-warning border border-dark table-bordered">
                <thead >
                    <tr>
                        <th>Projeto</th>
                        <th>Titulo</th>
                        <th>Descrição</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :job="job" v-for="(job,index) in jobs" :key="index">
                        <td class='align-middle'>{{ job.project }}</td>
                        <td class='align-middle'>{{ job.title }}</td>
                        <td class='align-middle'>{{ job.description }}</td>
                        <td class='align-middle'>
                            <button  class="btn btn-primary btn-sm m-2" @click="$router.push('/enc-painel/job/'+job.id)">Editar</button>
                            <button  class="btn btn-danger btn-sm m-2" @click="openModalRemove(job)">Excluir</button>
                            <button v-if="(job.is_publicado != 1)"  class="btn btn-warning btn-sm m-2" @click="openModalPublic(job)">
                                <b-icon icon="facebook"></b-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Modal
            v-if="openedModalRemove"
            :openModal="openedModalRemove"
            :size="'lg'"
            :classCustom="'modal-recuperar-senha'"
            name="nome"
        >
            <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                    <div
                        class="wrapper-icon-modal d-flex align-items-center justify-content-center"
                    >
                        <b-icon
                            @click="openedModalRemove = false"
                            icon="x"
                        ></b-icon>
                    </div>
                </b-col>
            </b-row>

            <div class="d-flex align-items-center justify-content-center flex-column">
                <h2>Remover Job</h2>
                <p class="my-4">Deseja realmente remover o job "{{ jobToRemove.title }}"?</p>
                <a href="javascript:void(0)" class="btn btn-danger" @click="removeJob()">Remover</a>
            </div>
        </Modal>
        <Modal
            v-if="openedModalPublic"
            :openModal="openedModalPublic"
            :size="'lg'"
            :classCustom="'modal-recuperar-senha'"
            name="nome"
        >
            <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                    <div
                        class="wrapper-icon-modal d-flex align-items-center justify-content-center"
                    >
                        <b-icon
                            @click="openedModalPublic = false"
                            icon="x"
                        ></b-icon>
                    </div>
                </b-col>
            </b-row>

            <div class="">
                <h2>Publicar em facebook</h2>
                <div class="row">
                    <div class="col">
                        <b-form-group id="input-group-2" label="Texto" label-for="Texto" class="mb-3">
                            <b-form-textarea
                                id="Texto"
                                v-model="publicar.desc"
                                name="Texto"
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <b-form-group id="input-group-2" label="Imagem" label-for="Imagem" class="mb-3">
                            <b-form-input
                                id="Imagem"
                                v-model="publicar.url"
                                name="Imagem"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <img :src="publicar.url" v-if="publicar.url" class='img-job rounded'>
                    </div>
                </div>
                <button  class="btn btn-warning btn-sm" @click="publicarJob">Enviar</button>
            </div>
        </Modal>
    </div>
</template>

<script>
import JobService from "../../../services/JobService";
import Utils from "../../../utils/utils.js";
import axios from 'axios';
export default {
    name: "Job",
    components: {
        Modal: () => import('../../../components/Modal'),
    },
    data() {
        return {
            jobs: [],
            openedModalRemove: false,
            openedModalPublic: false,
            publicar : {
                url: null,
                desc: null
            },
            jobPublicar: null,
            FB: {},
            accessToken:null
        }
    },
    methods: {
        publicarJob() {
            let _this = this
            this.FB.login(function (response) {
                if (response.status === 'connected') {
                    _this.Utils.swalLoading()
                    _this.accessToken = response.authResponse.accessToken;
                    _this.FB.api("5861169003946136/accounts", function (event) {
                        axios.post('https://graph.facebook.com/100578788142703/photos?message='+_this.publicar.desc+'&url='+_this.publicar.url+'&access_token='+event.data[0].access_token)
                        .then((response) => {
                            _this.Utils.close()
                            if (typeof response.data.id != 'undefined') {
                                _this.openedModalPublic = false;
                                _this.Utils.success('Publicado com sucesso!');
                                _this.jobPublicar.is_publicado = 1
                                _this.jobService.update(_this.jobPublicar,_this.jobPublicar.id).then(() => { })
                                this.loadJobs();
                            } else {
                                _this.Utils.error('Ops algo deu errado!');
                            }
                        })
                    })
                }

            });
        
            // this.Utils.swalLoading()
            // axios.post('https://graph.facebook.com/100578788142703/photos?message='+this.publicar.desc+'&url='+this.publicar.url+'&access_token=EAAGje4MdROkBABV2fcEd822IMcvirJ9Vl0Vh7oYjcUvjb92DbZAPfdq3h2rW8ZCDTxkZCaC9jWCWKSIDxZAYKFesR2NbMn7ugqpr9xgsWJfKZAtMX4kZCcNYc1XFJgrTImS3d2uC1w2DrfaZB9O7sMbcTxRna6FZAuZBF7s6jT3Ieu2D5OFrGwXRJ9XjQ7eRFBWwDixRJkTr2nDYgzjeXeKMk')
            // .then((response) => {
            //     this.Utils.close()
            //     if (typeof response.data.id != 'undefined') {
            //         this.openedModalPublic = false;
            //         this.Utils.success('Publicado com sucesso!');
            //         this.jobPublicar.is_publicado = 1
            //         this.jobService.update(this.jobPublicar,this.jobPublicar.id).then(() => { })
            //     } else {
            //         this.Utils.error('Ops algo deu errado!');
            //     }
            // })
        },
        openModalPublic(job) {
            this.jobPublicar = job
            this.publicar.url = job.link_image
            this.publicar.desc = job.description
            this.openedModalPublic = true;
        },
        openModalRemove(job) {
            this.jobToRemove = job;
            this.openedModalRemove = true;
        },
        removeJob() {
            this.Utils.swalLoading();
            this.jobService.remove(this.jobToRemove.id)
            .then(() => {
                this.openedModalRemove = false;
                this.Utils.success('Job removido com sucesso!');
                this.loadJobs();
            })
        },
        loadJobs() {
            this.jobService.getAll().then((response) => {
                this.jobs = response.data.data;
            })
        },
    },
    mounted () {
        let _this = this
        this.$nextTick(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '461225852749033',
                xfbml: true,
                version: 'v15.0'
            })
            window.FB.AppEvents.logPageView()
            _this.FB = window.FB
            console.log('FB SDK was initialized as mixin')
        };
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) { return }
            js = d.createElement(s); js.id = id
            js.src = '//connect.facebook.net/en_US/sdk.js'
            fjs.parentNode.insertBefore(js, fjs)
        }(document, 'script', 'facebook-jssdk'))
        })

        
    },
    created() {
        this.Utils = new Utils();
        this.jobService = new JobService();
        this.loadJobs();
        
    }
}
</script>

<style scoped>
.img-job{
    max-height: 200px;
}
</style>
